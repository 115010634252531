.form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.fields {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.subtitle {
  font-weight: 600;
}

.input {
  max-width: 100%;
}

.buttons {
  display: flex;
  justify-content: end;
  margin-top: 30px;
  gap: 15px;
}

.button {
  width: 120px;
}
