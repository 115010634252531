.imageContainer {
  width: 110px;
  height: 110px;
  border-radius: 8px;
  border: 1px solid var(--color-light-gray);
  position: relative;

  .buttonsContainer {
    display: none;

    .icon {
      color: var(--color-light-blue);
      cursor: pointer;
    }
  }

  &.selected {
    border-color: var(--color-light-blue);
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.block {
    width: 100%;
    height: 100%;
  }

  .disabledOverlay {
    background-color: var(--color-gray-background);
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
    width: 110px;
    height: 110px;
    border-radius: 8px;

    &.block {
      width: 100%;
      height: 100%;
    }
  }

  .image {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }

  .mainIcon {
    position: absolute;
    top: -7px;
    right: -6px;
    background-color: var(--color-white);
    border-radius: 50%;
    z-index: 1;

    &.checked {
      color: var(--color-green);
    }

    &.delete {
      color: var(--color-error-red);
      cursor: pointer;
    }
  }

  &.action:hover {
    .buttonsContainer {
      background-color: var(--color-gray-background);
      opacity: 0.7;
      position: absolute;
      top: 0;
      left: 0;
      width: 110px;
      height: 110px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;

      &.block {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.hideButton {
  display: none;
}
