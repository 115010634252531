.editTitle {
  text-transform: capitalize;
}

.inputBlock {
  display: flex;
  justify-content: space-between;
  gap: 24px;

  .number {
    width: 150px;
  }

  .text {
    width: 180px;
  }
}

.displayedPrice {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
}

.separator {
  height: 1px;
  width: 100%;
  margin: 16px 0;
  background-color: lightgray;
}

.formItem {
  margin: 0;
}

.buttonsContainer {
  display: flex;
  justify-content: end;
  width: 100%;
  gap: 16px;
  margin-top: 16px;
}