@use '../form.module.scss';

.optionWrapper {
  display: flex;
  gap: 10px;
  align-items: center;

  .colorBall {
    width: 15px;
    height: 15px;
    border-radius: 15px;
  }
}
