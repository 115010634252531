.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #f0f0f0;
}

.image {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.content {
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 2fr 1fr 1fr 1fr 1fr 0.5fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 5px;
  align-items: center;
}
