@mixin reset-list {
  display: flex;
  height: 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.title {
  @include reset-list;
  align-items: center;
  margin-bottom: 15px;
}

.footer {
  @include reset-list;
  justify-content: space-between;
  align-items: center;
}

.productList {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.product {
  display: flex;
  gap: 8px;
}

.selectors {
  margin-bottom: 25px;
  padding-bottom: 25px;
  box-shadow: inset 0px -1px 0px var(--color-light-gray);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas:
    'selector1 selector2 selector3'
    'button button button';
  grid-row-gap: 8px;
}

.button {
  width: 110px;
}

.selector {
  width: 150px;
}

.input {
  width: 150px;
}
