.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.title {
  margin: 0;
}

.button {
  width: fit-content;
}

.link {
  cursor: pointer;
  font-weight: 700;
}
