.promoTypeWrapper {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.limitedCountWrapper {
  display: flex;
  align-items: center;
  gap: 15px;
}

.select {
  width: 230px;
}
