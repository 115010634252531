.textCell {
  margin-bottom: 0 !important;
}

.skeleton {

  :global {

    .ant-skeleton-title {
      margin: 0 0 12px 0;

    }

    .ant-skeleton-paragraph {
      margin: 0;
      margin-block-start: 0 !important;
      
      li {
        width: 100% !important;
      }
    }
  }
}