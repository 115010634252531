body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$colors: (
  primary: #f6f7fb,
  blue: #0000ff,
  blue-hover: #4b3ae4,
  light-blue: #5890ff,
  error-red: #ff0000,
  green: #2ecd12,
  white: #ffffff,
  black: #000000,
  gray-background: #dfdede,
  light-gray: #f5f5f5,
  dark-gray: #999999,
  gray-text: #454655,
);

:root {
  @each $key, $value in $colors {
    --color-#{$key}: #{$value};
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  all: unset;
  cursor: pointer;
  color: var(--color-gray-text);
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;

  &:hover {
    color: var(--color-blue-hover);
  }
}

.ant-skeleton-input {
  width: 100% !important;
  min-width: 100% !important;
}

.ant-skeleton {
  width: 100% !important;
}

.swiper-slide {
  display: flex;
  width: auto;
  height: auto;
}

.ant-input-number-affix-wrapper-disabled {
  background-color: var(--color-light-gray);
}

.ant-input-number-disabled {
  background-color: var(--color-light-gray);
}
