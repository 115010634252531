.form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.buttons {
  display: flex;
  justify-content: end;
  margin-top: 30px;
  gap: 15px;
}

.button {
  width: 120px;
}
