.container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px;
  margin: 5px 0;
  background: #F7F8F9;
  border: 1px solid #ececec;
  border-radius: 5px;
}

.contentWrapper {
  display: flex;
  justify-content: space-between;
}

.content {
  display: flex;
  gap: 10px;
  align-items: center;
}

.btnWrapper {
  display: flex;
  gap: 15px;
  margin-right: 20px;
}

.footer {
  display: flex;
  gap: 30px;
  align-items: center;
}
