.contentWrapper {
  display: flex;
  flex-direction: column;

  .btnWrapper {
    display: flex;
    width: 100%;
    gap: 10px;
    flex-direction: column;
    align-items: flex-end;

    .btn {
      width: 100px;
    }
  }
}
