.container {
  border: 1px solid #efeded;
  margin: 5px 0px;
  border-radius: 5px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  padding: 8px 16px;
  gap: 20px;
  background: #f7f8f9;
  align-self: stretch;
}

.column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  min-height: 48px;
  width: 100%;
}

.info {
  display: flex;
  align-items: center;
  gap: 15px;
}

.isExpanded {
  transform: rotate(90deg);
  transition: 0.2s;
}

.isNotExpanded {
  transform: rotate(0deg);
  transition: 0.2s;
}

.icon {
  height: 15px;
  transition: 0.6s;
  opacity: 1 !important;
}

.icon :hover {
  color: rgb(59, 103, 236);
  transition: 0.6s;
}

.items {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 8px 16px;
  gap: 20px;
  flex-wrap: wrap;
}

.item {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 32px;
  height: 32px;
  object-fit: contain;
  border-radius: 5px;
}

.title {
  display: flex;
  width: 120px;
  height: 22px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}

.controls {
  display: flex;
  gap: 15px;
  justify-content: flex-end;
  align-items: center;
}

.date {
  height: 22px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}

.expandable {
  padding: 16px;
  background: var(--color-white);
}

.popconfirmMessage {
  max-width: 200px;
}

.highlighted {
  background-color: white !important;
  cursor: default !important;
  color: #00ba1d !important;
  border-color: #00ba1d !important;
}
