.container {
  height: 100%;
  border: 1px solid #cfd1d4;
  border-radius: 4px;
  overflow: hidden;
}

.header {
  display: flex;
  gap: 10px;
  padding: 10px 8px;
  background-color: #fff;
}
