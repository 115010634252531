.list {
  margin: 30px 0 50px;

  .item {
    display: flex;
    flex-direction: column;
    gap: 10px;

    p {
      padding: 0;
      margin: 0;
    }

    :first-child {
      font-weight: 600;
    }
  }

  .quantityWrapper {
    display: flex;
    flex-direction: row;
    gap: 5px;

    .quantity {
      font-weight: 600;
      color: rgba(24, 144, 255, 1);
    }
  }

  :global {
    .ant-list-header {
      font-weight: 600;
      font-size: 16px;
      border-radius: 8px 8px 0 0;
      background: var(--color-light-gray);
    }
  }
}
