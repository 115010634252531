@use '../form.module.scss';

.required {
  color: var(--color-error-red);
}

.error {
  margin-top: 4px;
  color: var(--color-error-red);
}
