.showableVariation {
  display: flex;
  width: 100%;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.name {
  padding: 5px 0 15px 0;
  font-weight: 700;
  text-transform: capitalize;
}

.values {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.tag {
  margin: 0;
}

.buttons {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}
