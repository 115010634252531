.header {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 12px 0;
}
