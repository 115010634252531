@use '../form.module.scss';

.uploader {
  height: 200px;
  width: 100%;
  border: 4px dashed var(--color-gray-background);
  border-radius: 10px;
  position: relative;

  .upload {
    top: 0;
    position: absolute;
    width: 100%;
    height: 200px;
    outline: none;
    opacity: 0;
    cursor: pointer;
  }

  .titleWrapper {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    .uploadIcon svg {
      color: var(--color-light-blue);
      height: 50px;
      width: 100%;
    }

    .title {
      font-family: Arial;
      font-weight: 700;
      color: var(--color-dark-gray);
      position: absolute;
      width: 100%;
      margin-top: 70px;
      text-align: center;
    }
  }
}

.imageWrapper {
  max-width: 300px;
  max-height: 300px;
}
