.wrapperIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .arrowIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    cursor: pointer;

    :hover {
      font-size: 20px;
      color: var(--color-green);
      transition: 0.2s;
    }
  }
}
