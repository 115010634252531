.container {
  display: flex;
  justify-content: space-between;
}

.textarea {
  width: 50%;
  textarea {
    resize: none;
    height: 100px;
  }
}

.buttons {
  display: flex;
  gap: 15px;
}
