.container {
  width: 100%;
  padding: 16px;
  background-color: white;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
}

.header {
  display: flex;
  justify-content: space-between;
}
