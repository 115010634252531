.slideContent {
  display: flex;
  gap: 20px;

  .uploader,
  .slideDescription {
    min-width: 300px;
  }

  .inputWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.actionButton {
  margin-top: 12px;
}
