@use '../form.module.scss';

.container {
  max-width: none;
}

.richEditorControls {
  margin-bottom: 5px;
  user-select: none;
}

.button {
  color: var(--color-dark-gray);
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;

  &.active {
    color: var(--color-light-blue);
  }
}

.editorContainer {
  border: 1px solid var(--color-gray-background);
  border-radius: 8px;
  padding: 16px;
  background-color: var(--color-white);

  .editor {
    min-height: 90px;
    border-top: 1px solid var(--color-gray-background);
    padding: 8px 0;
  }
}

.header1 {
  font-size: 39px;
  line-height: 40px;
  margin: 10px 0;
  font-weight: 700;
}

.header2 {
  font-size: 31px;
  line-height: 40px;
  margin: 10px 0;
  font-weight: 700;
}

.header3 {
  font-size: 23px;
  line-height: 40px;
  margin: 10px 0;
  font-weight: 700;
}

.blockquote {
  border-left: 5px solid var(--color-light-gray);
  margin: 16px 0;

  div {
    padding: 16px;
  }
}
