.efficiencySkeleton {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  background: #f7f8f9;
  border: 1px solid #ececec;
  border-radius: 5px;
  height: fit-content;
}

.title {
  font-weight: 600;
}
