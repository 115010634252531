.container {
  padding: 20px;
  border: 1px solid rgba(217, 217, 217, 1);
  border-radius: 12px;
  font-size: 14px;
  min-height: 150px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgba(133, 133, 133, 1);
}
.content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.items {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid rgba(217, 217, 217, 0.32);
}

.value {
  color: rgba(133, 133, 133, 1);
}
