.historySection {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin: 30px 0;
}

.header {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
}

.title {
  height: 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
