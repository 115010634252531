.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.filters {
  display: flex;
  gap: 15px;
}

.search {
  max-width: 250px;
  min-width: 220px;
}

.subcategoryPage {
  height: 100%;
}
