.info {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;

  .infoItem {
    display: flex;
    gap: 5px;
    align-items: center;

    .trackLink {
      color: var(--color-blue);
    }

    :first-child {
      font-weight: 600;
    }
  }
}

.comment :first-child {
  font-weight: 600;
  padding-right: 5px;
}
