.container {
  display: flex;
  flex-direction: column;
}

.section {
  display: flex;
  justify-content: flex-start;
  gap: 30px;
}

.title {
  font-weight: 700;
}

.input {
  font-weight: 700;
  width: 70px;
}

.actionWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 15px;
}
