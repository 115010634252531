.container {
  height: 100%;
  overflow: auto;
}

.sections {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.field {
  min-height: 58px;
}
