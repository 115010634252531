.container {
  display: flex;
  width: 100%;
  gap: 16px;
  margin: 30px 0;
}

.section {
  background-color: white;
  width: 100%;
}

.field {
  max-width: none;
}
