.formLoading {
  position: absolute;
  transform: translate(-50%);
  top: 50%;
  left: 50%;
}

.createProductPage {
  height: 100%;
  overflow: auto;
  margin: 0 auto;
  max-width: 1000px;
  padding: 0 16px;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color-dark-gray);
  margin: 26px 0 !important;
}

.product {
  display: flex;
  gap: 24px;
}

.meta {
  display: flex;
  flex-direction: column;
}

.buttons {
  display: flex;
  gap: 8px;
  margin-top: 8px;
}

.field {
  max-width: 600px;
}
