.container {
  padding: 15px;
  border-inline-end: 1px solid rgba(5, 5, 5, 0.06);
  background-color: white;
}

.logo {
  width: 170px;
  padding: 15px 0;
}

.menu {
  border-inline-end: none !important;
}

.footer {
  position: sticky;
  top: 100%;
}
