.container {
  display: flex;
  justify-content: space-between;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 10px;
  width: 100%;
}

.field {
  width: 100%;
  max-width: 300px;
}

.buttons {
  display: flex;
  gap: 15px;
}
