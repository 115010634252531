.auth {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form {
  min-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.logo {
  display: flex;
  justify-content: center; 
  padding: 42px;
}

.logoImage {
  max-width: 400px;
}
