.warningMessage {
  width: 200px;
}

.actionWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding-top: 15px;
}

.moreActionIcon {
  font-size: 150%;
}

.table {
  min-width: 100% !important;
}

.tag {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select {
  width: 100%;
}

.skeleton {
  :global {
    .ant-skeleton-title {
      margin: 0 0 12px 0;
    }

    .ant-select-selection-search {
      background-color: green !important;
    }

    .ant-skeleton-paragraph {
      margin: 0;
      margin-block-start: 0 !important;

      li {
        width: 100% !important;
      }
    }
  }
}

.disputeWrapper {
  :global {
    .ant-select-select {
      svg {
        color: black;
      }
    }

    .ant-select-selector {
      background-color: #f6b26b !important;

      span {
        color: black;
      }

      .ant-select-selection-item {
        color: white;
      }
    }

    .ant-select-selection-search {
      background-color: #f6b26b !important;
    }
  }
}

.gray {
  :global {
    .ant-select-selection-search {
      background-color: #abaeab !important;
    }
    .ant-select-selector {
      background-color: #abaeab !important;
    }
    .ant-select-selector span {
      color: white;
    }
    .ant-select-select svg {
      color: white;
    }
  }
}

.red {
  :global {
    .ant-select-selection-search {
      background-color: #df2d2d !important;
    }
    .ant-select-selector {
      background-color: #df2d2d !important;
    }
    .ant-select-selector span {
      color: white;
    }
    .ant-select-select svg {
      color: white;
    }
  }
}

.green {
  :global {
    .ant-select-selection-search {
      background-color: rgb(14, 201, 14) !important;
    }
    .ant-select-selector {
      background-color: rgb(14, 201, 14) !important;
    }
    .ant-select-selector span {
      color: white;
    }
    .ant-select-select svg {
      color: white;
    }
  }
}

.refundWrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
