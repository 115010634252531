.container {
  padding: 16px;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
}

.header {
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}

.buttons {
  display: flex;
  gap: 16px;
}

.fields {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 8px;
}
