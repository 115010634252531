.modalWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .title {
    font-weight: 600;
    font-size: 20px;
  }

  .form {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    gap: 30px;

    .buttonWrapper {
      display: flex;
      justify-content: end;
      margin-top: 45px;
      gap: 15px;

      .button {
        width: 120px;
      }
    }
  }
}
