.container {
  display: flex;
  gap: 8px;
}

.label-top {
  flex-direction: column;
}

.label-left {
  flex-direction: row;

  & .label::after {
    content: ':';
  }
}

.label {
  margin: 0;
  color: #585d68;
}

.value {
  font-weight: 500;
}
