.reason {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}

.link {
  cursor: pointer;
}

.loader {
  position: absolute;
  top: 35px;
  width: 45%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
