@use '../arrow-swiper.module.scss';

.swiperWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  width: 120px;

  .imageWrapper {
    width: 100px;
    height: 100px;

    .image {
      width: 100px;
      height: 100px;
      object-fit: cover;
      border-radius: 8px;
      box-sizing: border-box;
      border: 2px solid var(--color-white);
    }

    .selected {
      border: 2px solid var(--color-green);
    }
  }

  .next {
    transform: rotate(180deg);
  }
}
