.productList {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: scroll;
  max-height: 500px;
}

.selectorWrapper {
  margin-bottom: 25px;
  padding-bottom: 25px;
  box-shadow: inset 0px -1px 0px var(--color-light-gray);
  display: flex;
  justify-content: space-between;
}

.toggle {
  width: 110px;
}

.selector {
  width: 150px;
}
