.purchseBilling {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 40px;
}

.imgStyle {
  height: auto;
  width: auto;
  max-width: 25px;
  max-height: 25px;
  cursor: pointer;
}

.customer {
  width: 50%;
}

.parcel {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 30px;
}

.shipments {
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  gap: 30px;
}
