.selectWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0 80px;

  .select {
    width: 60%;
  }
}
