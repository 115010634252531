.container {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 5px;
  overflow: hidden;
}

.tags {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  overflow: hidden;
}

.tag {
  width: 100%;
  overflow: hidden;
  height: fit-content;
  margin: 0;
  border: 1px solid #f0f0f0 !important;

  & > span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.select {
  height: fit-content;
  overflow: hidden;
}
