.container {
  display: flex;
  justify-content: space-between;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 10px;
}

.title {
  font-weight: 600;
}
