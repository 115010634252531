.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  padding: 10px;
  border-radius: 10px;
  background-color: white;
  border: 1px solid #f0f0f0;
}

.field {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
