.container {
  padding: 10px;
  background: white;
  border: 1px solid #efeded;
  border-radius: 10px;
}

.content {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1.5fr 1fr;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  min-height: 48px;
  width: 100%;
}

.column {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

.rotated {
  transform: rotate(90deg);
}

.title {
  display: flex;
  flex-direction: column;
}

.status {
  font-size: 10px;
}

.images {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
  flex-wrap: wrap;
}

.image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 30px;
  height: 30px;
  object-fit: contain;
  border-radius: 5px;
}

.details {
  padding: 10px 0;
}
