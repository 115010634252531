.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 19px;
}

.inputRefund {
  max-width: 60px;
}

.select {
  width: 60%;
}

.tableWrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 44px 0 54px;

  .title {
    padding: 0;
    margin: 0;
    font-weight: 500;
    font-size: 16px;
  }

  .infoWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .totalWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      padding-right: 20px;
      gap: 10px;

      p {
        margin: 0;
        font-weight: 600;
        font-size: 16px;
      }

      span {
        font-size: 16px;
      }
    }

    p {
      margin: 0;
      font-weight: 500;
      font-size: 16px;
    }
  }
}

.selectWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 80px;

  .refundSumWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .inputNumber {
    width: 30%;
    margin-right: 10px;
  }
}

.sum {
  color: rgba(24, 144, 255, 1);
}
