.mediaBlock {
  width: 350px;

  .imagesList {
    max-height: 400px;
    overflow: auto;
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 10px 0;
  }

  .selects {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;
  }
}
