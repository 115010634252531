.discountBarWrapper {
  display: flex;
  flex-direction: column;

  .selectWrapper {
    display: flex;
    flex-direction: row;
    gap: 10px;

    .select {
      min-width: 400px;
    }
  }
}
