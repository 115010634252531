.modal {
  min-width: 700px;
  min-width: 760px;
  resize: none;

  .imageWrapper {
    display: flex;
    max-height: 400px;
  }
}
