.imageSize {
  :global {
    .ant-skeleton-image {
      width: 52px !important;
      height: 52px !important;

      .ant-skeleton-image-svg {
        width: 24px !important;
        height: 24px !important;
      }
    }
  }
}

.cellSize {
  width: 52px;
  height: 52px;
  cursor: pointer;

  .imageWrapper {
    width: 52px;
    height: 52px;

    .image {
      object-fit: cover;
    }
  }
}

.hideButton {
  display: none;
}

.modalContent {
  padding-top: 24px;
}
