.container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-width: 20px;
  object-fit: contain;
  border-radius: 5px;
  height: 45px;
  min-width: 45px;
}

.title {
  width: 100%;
}

.input {
  max-width: 50px;
}

.max {
  width: 30px;
}
