.main {
  margin-bottom: 20px;
}

.variant {
  overflow-wrap: break-word;
}

.price {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
}
