.container {
  display: flex;
  gap: 10px;
  padding: 10px;
  border-radius: 10px;
  background-color: white;
  border: 1px solid #f0f0f0;
}

.image {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.content {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  gap: 5px;
}

.title {
  grid-column-start: 1;
  grid-column-end: 3;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 10px;
}

.link {
  display: flex;
  gap: 10px;
}

.icon {
  cursor: pointer;
}
