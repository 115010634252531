.imagesContainer {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-top: 8px;
}

.buttonsContainer {
  display: flex;
  justify-content: end;
  width: 100%;
  gap: 16px;
  margin-top: 16px;
}