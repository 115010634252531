.imageWrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  transition: transform 0.1s;
  z-index: 2;
}

.imageWrapper:hover {
  transform: scale(4);
  position: relative;
  z-index: 300;
}

.image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 30px;
  min-width: 20px;
  object-fit: contain;
}

.search {
  width: 120px;
  cursor: default !important;
}

.deleteWrapper {
  display: flex;
  justify-content: center;
  gap: 20px;
}
