.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.buttons {
  display: flex;
  gap: 20px;
}

.selects {
  display: grid;
  gap: 20px;
  grid-template-columns: 150px 400px 400px;
}

.field {
  width: 100%;
}

.searchType {
  grid-area: searchType;
}

.search {
  grid-area: search;
}

.filter {
  grid-area: filter;
}
