.selectContainer {
  display: flex;
  gap: 8px;
}

.search {
  width: 85%;
}

.types {
  margin-top: 16px;
  height: 200px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.type {
  padding: 8px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-weight: 700;
}

.delete {
  cursor: pointer;
}

.buttons {
  display: flex;
  justify-content: end;
  margin-top: 30px;
  gap: 15px;
}

.button {
  width: 120px;
}
