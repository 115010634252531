.container {
  position: relative;
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 20px;
  min-height: 250px;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.statistics {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  padding: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
}

.spin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
