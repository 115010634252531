.warning {
  color: red !important;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.field {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.select {
  width: 60%;
}

.input {
  width: 30%;
  margin-right: 10px;
}
