.header {
  display: flex;
  justify-content: space-between;
}

.title {
  height: 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.returnItemWrapper {
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  margin-top: 8px;
}
