.orderLinkContainer {
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 10px 0px 10px 0;
  padding: 5px;
  cursor: pointer;
}

.input {
  width: 100%;
}

.dottedIcon {
  border: 5px dotted rgba(128, 128, 128, 0.496);
  width: 3px;
  height: 13px;
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.linkIcon {
  color: #1890ff;
  padding-right: 10px;
}

.deleteIcon {
  color: rgba(0, 0, 0, 0.45);
}

.inputWrapper {
  border: 1px solid rgba(128, 128, 128, 0.563);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.listWrapper {
  max-height: 320px;
  overflow: auto;
}

.orderLinkContainer:first-child .inputWrapper {
  border: 1.5px solid #1890ff;
}

.btnWrapper {
  display: flex;
  justify-content: space-between;
  padding: 0 5px 0 30px;
  margin-top: 25px;
}
