.shipmentItem {
  border: 1px solid #efeded;
  margin: 5px 0px;
  border-radius: 5px;
}

.shipmentContent {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  padding: 8px 16px;
  gap: 20px;
  background: #F7F8F9;
  align-self: stretch;
}

.shipmentRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  gap: 20px;
  height: 48px;
  width: 100%;
}

.photolist {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 8px 16px;
  gap: 20px;
  height: 48px;
  width: 100%;
}

.image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  min-width: 20px;
  object-fit: contain;
  border-radius: 5px;
}

.icon {
  height: 15px;
  transition: 0.6s;
  opacity: 1!important;
}

.icon :hover {
  color: rgb(59, 103, 236);
  transition: 0.6s;
}

.isViewed {
  transform: rotate(90deg);
  transition: 0.2s;
}

.isNotViewed {
  transform: rotate(0deg);
  transition: 0.2s;
}

.shipmentDetailed {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 8px;
  background: var(--color-white);
}

.title {
  height: 22px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}

.btnWrapper {
  display: flex;
  gap: 15px;
  justify-content: flex-end;
  width: 20%;
}

.popconfirmMessage {
  max-width: 200px;
}

.shipmentFooter {
  display: flex;
  margin-left: 0px;
  padding: 20px;
}

.selectWrapper {
  display: flex;
  gap: 30px;
}

.shipmentFooterContent {
  display: flex;
  width: 100%;
  justify-content: start;
  gap: 50px;
  align-items: center;
  margin-left: 0px;
}

.shipmentColumn {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.supplier {
  width: 120px;
}

.shipReason {
  width: 180px;
}

.date {
  width: 290px;
}

.trackingInfo {
  display: flex;
  gap: 30px;
  justify-content: space-between;
}