.container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0 16px;

    .form {
        display: flex;
        gap: 16px;
        align-items: flex-end;

        .autocomplete {
            min-width: 150px;
        }
    }
}

.error_details {
    pre {
        background-color: #fbfbfb;
        padding: 8px;
        border-radius: 8px;
    }
}

.no_errors {
    text-align: center;
}