.actions {
  z-index: 1060;
}

.popconfirmMessage {
  max-width: 200px;
}

.moreIcon {
  font-size: 150%;
  cursor: pointer;
}
