@mixin label {
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 10px;
  top: 5px;
  transition: 0.2s ease all;
}

.floatLabel {
  position: relative;
  width: 250px;
}

.fullWidth {
  width: 100%;
}

.asPlaceholder {
  @include label;
  color: #c0bbbb;
}

.asError {
  border: 1px solid red !important;
}

.asLabel {
  @include label;
  top: -8px;
  font-size: 12px !important;
  background: white;
  padding: 0 4px;
  margin-left: -4px;
  color: rgb(91, 147, 237);
}

.input {
  border: 1px solid rgb(91, 147, 237);
}

.selectedInput {
  border: 1px solid #00BA1D !important;
}

.selectedInput label {
  background: #000000;
}

.validationError {
  color: red;
}

.hidePlaceholder {
  display: none;
}
