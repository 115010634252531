.layout {
  height: 100vh;
  overflow: hidden;
  background-color: white;
}

.content {
  padding: 24px;
  overflow: scroll;
}
