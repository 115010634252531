.content{
  width: 100%;
  display: flex;
  flex-direction: column;
}

.variationTypeWrapper {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom:15px;
}

.name {
  padding: 15px 0;
  font-weight: 700;
}

.valueInputWrapper {
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 0 0 15px 10px;
}

.valueInput {
  width: 300px;
  min-width: 170px;
}

.addOptionValue {
  width: 300px;
  margin: 0 0 15px 10px;
}

.btnWrapper {
  display: flex;
  justify-content: end;
  gap: 15px;
}

.colorBall {
  width: 15px;
  height: 15px;
  border-radius: 15px;
}

.optionWrapper {
  display: flex;
  gap: 10px;
  align-items: center;
}
