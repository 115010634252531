.form {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.field {
  width: 50%;

  & > div {
    width: 100%;
  }
}
