.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.header {
  display: flex;
  gap: 16px;
  max-width: 450px;
}

.supplier {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
