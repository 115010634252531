.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
}

.spin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  margin: 0;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 150px;
}
