.packages {
  max-height: 350px;
  overflow: scroll;
}

.package {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;
  justify-content: end;
  width: 100%;
}

.number {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.cost {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 700;
}

.type {
  text-align: center;
}

.total {
  font-weight: 700;
}

.buttons {
  display: flex;
  justify-content: end;
  margin-top: 30px;
  gap: 15px;
}

.button {
  width: 120px;
}
