.container {
}

.header {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 12px 0;
}

.controls {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.checkboxes {
  display: flex;
  gap: 10px;
}

.select {
  display: block;
  width: 100%;
}
