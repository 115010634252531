.title {
  width: 244px;
  height: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  white-space: nowrap;
  color: rgb(83, 101, 236);
}

.plusIcon {
  color: rgb(83, 101, 236);
}

.addVariantBtn {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  height: 20px;
  cursor: pointer;
  margin-bottom: 15px;
}

.addVariantBtn:hover .plusIcon {
  color: blue;
  transition: 0.4s;
}

.addVariantBtn:hover .title {
  color: blue;
  transition: 0.4s;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 15px;
  margin: 15px;
}

.variationSelectType {
  width: 320px;
  margin-bottom: 10px;
}

.saveBtnWrapper {
  display: flex;
  margin-top: 15px;
}
