@mixin reset-list {
  display: flex;
  height: 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.title {
  @include reset-list; 
  align-items: center;
  box-shadow: inset 0px -1px 0px var(--color-light-gray);
  padding-bottom: 16px;
  margin-bottom: 24px;
}

.footer {
  @include reset-list; 
  justify-content: flex-end;
  box-shadow: inset 0px 1px 0px var(--color-light-gray);
  padding-top: 16px;
  margin-top: 24px;
}

.productList {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.selectorWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.selector {
  width: 150px;
}
