.container {
  display: flex;
  flex-direction: column;
  gap: 36px;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 36px;
}

.column {
  display: grid;
  grid-auto-rows: 1fr;
  gap: 36px;
  height: fit-content;
}

.datePicker {
  max-width: 320px;
}

.label {
  font-weight: 500;
  color: rgba(133, 133, 133, 1);
}
