.productList {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.selectorWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  gap: 10px;
  margin-bottom: 10px;
}
