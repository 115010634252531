.variationSelectType {
  width: 320px;
  margin-bottom: 10px;
}

.inputGroup {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;
}

.valueInput {
  width: 305px;
}

.addOptionValue {
  width: 305px;
  margin-bottom: 30px;
}

.valueGroup {
  max-width: 320px;
}

.valuesBlock {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 15px;
}

.optionWrapper {
  display: flex;
  gap: 10px;
  align-items: center;
}

.colorBall {
  width: 15px;
  height: 15px;
  border-radius: 15px;
}

.colorSelect {
  width: 300px;
}

