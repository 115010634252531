.imageWrapper {
  position: relative;
  overflow: hidden;
  width: 100%;

  .image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
