.container {
  display: grid;
  grid-template-columns: minmax(auto, 400px) minmax(auto, 400px);
  grid-template-rows: 1fr 1fr;
  gap: 20px;
  margin-bottom: 30px;
}

.field {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}
