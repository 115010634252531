.purchase {
  display: flex;
}

.content {
  width: 100%;
}

.info {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 10px;
}

.comment {
  display: flex;
  width: 100%;
}

.editBtn {
  width: 40px;
  margin-left: 10px;
}
