.container {
  margin: 30px 0 50px;

  :global {
    .ant-list-header {
      font-weight: 600;
      font-size: 16px;
      border-radius: 8px 8px 0 0;
      background: var(--color-light-gray);
    }
  }
}

.item {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.updatedQuantity {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.icon {
  color: rgba(24, 144, 255, 1);
}
