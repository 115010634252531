@mixin reset-list {
  display: flex;
  height: 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.title {
  @include reset-list;
  align-items: center;
  margin-bottom: 15px;
}

.footer {
  @include reset-list;
  justify-content: flex-end;
}

.productList {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: scroll;
  max-height: 500px;
}

.selectors {
  margin-bottom: 25px;
  padding-bottom: 25px;
  box-shadow: inset 0px -1px 0px var(--color-light-gray);
  display: flex;
  justify-content: space-between;
}

.toggle {
  width: 110px;
}

.selector {
  width: 150px;
}
