.markdownDescriptions {
  padding: 12px 0;
  max-width: 600px;

  .inputWidth {
    max-width: 100%;
  }

  .addDescriptionButton {
    margin-top: 8px;
  }
}

.error {
  border: 1px solid var(--color-error-red);
}
