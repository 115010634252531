.container {
  max-width: 300px;
}

.inputWrapper {
  display: flex;
  gap: 8px;
}

.inputWidth {
  width: 100%;
}

.label-top {
  flex-direction: column;
}

.label-left {
  flex-direction: row;
  align-items: center;

  & .label {
    margin: 0;
    flex-shrink: 0;

    &::after {
      content: ':';
    }
  }
}

.error {
  margin-top: 4px;
  color: var(--color-error-red);
}

.label {
  display: flex;
  gap: 2px;
}

.required {
  color: var(--color-error-red);
}
