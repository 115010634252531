.container {
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  font-size: 14px;

  &:enabled:hover {
    color: #4096ff;
  }
}

.highlighted {
  &:enabled {
    color: #1677ff;
  }
}
