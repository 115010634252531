.formContainer {
  position: relative;

  .title {
    color: var(--color-dark-gray);
  }

  .formContent {
    padding: 15px 0;

    .fields {
      flex-grow: 1;
      max-width: 60%;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .imagesContent {
        width: 100%;
        height: 100%;
      }

      .field {
        width: 100%;
        max-width: 500px;
      }

      .buttons {
        display: flex;
        gap: 8px;
      }
    }
  }
}

.formLoading {
  position: absolute;
  transform: translate(-50%);
  top: 50%;
  left: 50%;
}
