.metaCardConteiner {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 8px;
}

.field {
  max-width: 600px;
}
