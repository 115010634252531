.productList {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: scroll;
  max-height: 500px;
}

.selectorWrapper {
  margin-bottom: 10px;
}
