@use '../form.module.scss';

.colorSelect {
  width: 100%;
}

.listItem {
  margin-bottom: 10px;
}

.links {
  display: flex;
  flex-direction: column;

  &.lastRemovable {
    .listItemContainer:first-child {
      .trash {
        display: none;
      }
    }
  }
}

.notUnique {
  border-color: var(--color-error-red);
  box-shadow: none;

  :global {
    .ant-select-selector {
      border-color: var(--color-error-red) !important;
    }
  }

  &:hover {
    border-color: var(--color-error-red) !important;
    box-shadow: none;
    outline: none;
  }

  &:focus {
    box-shadow: none;
  }
}

.optionWrapper {
  display: flex;
  gap: 10px;
  align-items: center;
}

.colorBall {
  width: 15px;
  height: 15px;
  border-radius: 15px;
}

.listItemContainer {
  display: flex;
  align-items: baseline;
  gap: 8px;

  .trash {
    svg {
      color: var(--color-black);
    }
  }
}
