.formFields {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 600px;

  .inputWidth {
    max-width: 100%;
  }

  .priceWrapper {
    display: flex;
    gap: 24px;
    justify-content: space-between;

    .displayedPrice {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 14px;
    }
  }
}

.info {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.error {
  border: 1px solid var(--color-error-red);
}
