.card {
  width: 100%;
  box-sizing: 'border-box';
  margin: 20px 0;
}

.title {
  width: 244px;
  height: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.02em;
  white-space: nowrap;
}

.addVariantBtn {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 20px;
  height: 20px;
  cursor: pointer;
  margin-bottom: 15px;
}

.addVariantBtn:hover .plusIcon {
  color: blue;
  transition: 0.4s;
}

.addVariantBtn:hover .title {
  color: blue;
  transition: 0.4s;
}

